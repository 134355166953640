var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-simple-table', {
          staticClass: "mt-5",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn() {
              return [_vm.isOtherAssetsCopy ? _c('tbody', _vm._l(_vm.otherAssets, function (otherAsset, idx) {
                return _c('tr', {
                  key: idx
                }, [_c('td', [_c('v-select', {
                  staticClass: "pa-5",
                  attrs: {
                    "dense": "",
                    "items": _vm.typeOptions,
                    "label": "Type of assets",
                    "disabled": _vm.FIELDS_DISABLED
                  },
                  model: {
                    value: otherAsset.type,
                    callback: function callback($$v) {
                      _vm.$set(otherAsset, "type", $$v);
                    },
                    expression: "otherAsset.type"
                  }
                })], 1), _c('td', [_c('v-combobox', {
                  staticClass: "pa-5",
                  attrs: {
                    "items": _vm.ownershipOptions,
                    "label": "Ownership",
                    "dense": "",
                    "disabled": _vm.FIELDS_DISABLED
                  },
                  model: {
                    value: otherAsset.ownership,
                    callback: function callback($$v) {
                      _vm.$set(otherAsset, "ownership", $$v);
                    },
                    expression: "otherAsset.ownership"
                  }
                })], 1), _c('td', [_c('v-text-field', {
                  attrs: {
                    "dense": "",
                    "type": "text",
                    "placeholder": "Description",
                    "label": "Description",
                    "disabled": _vm.FIELDS_DISABLED
                  },
                  model: {
                    value: otherAsset.description,
                    callback: function callback($$v) {
                      _vm.$set(otherAsset, "description", $$v);
                    },
                    expression: "otherAsset.description"
                  }
                })], 1), _c('td', [_c('v-text-field', {
                  attrs: {
                    "dense": "",
                    "type": "text",
                    "placeholder": "Value, NZD",
                    "label": "Value, NZD",
                    "disabled": _vm.FIELDS_DISABLED
                  },
                  on: {
                    "focus": function focus($event) {
                      var _vm$otherAssetsCopy, _vm$otherAssetsCopy$i;

                      return _vm.inputNumberType($event, (_vm$otherAssetsCopy = _vm.otherAssetsCopy) === null || _vm$otherAssetsCopy === void 0 ? void 0 : (_vm$otherAssetsCopy$i = _vm$otherAssetsCopy[idx]) === null || _vm$otherAssetsCopy$i === void 0 ? void 0 : _vm$otherAssetsCopy$i.value, idx);
                    },
                    "blur": function blur($event) {
                      var _vm$otherAssetsCopy2, _vm$otherAssetsCopy2$;

                      return _vm.inputTextType($event, (_vm$otherAssetsCopy2 = _vm.otherAssetsCopy) === null || _vm$otherAssetsCopy2 === void 0 ? void 0 : (_vm$otherAssetsCopy2$ = _vm$otherAssetsCopy2[idx]) === null || _vm$otherAssetsCopy2$ === void 0 ? void 0 : _vm$otherAssetsCopy2$.value, idx);
                    }
                  },
                  model: {
                    value: _vm.otherAssetsCopy[idx].value,
                    callback: function callback($$v) {
                      _vm.$set(_vm.otherAssetsCopy[idx], "value", $$v);
                    },
                    expression: "otherAssetsCopy[idx].value"
                  }
                })], 1), _c('td', [_c('v-icon', {
                  attrs: {
                    "disabled": _vm.FIELDS_DISABLED
                  },
                  on: {
                    "click": function click($event) {
                      $event.preventDefault();
                      return _vm.removeAssetsData(idx);
                    }
                  }
                }, [_vm._v(" mdi-delete ")])], 1)]);
              }), 0) : _vm._e()];
            },
            proxy: true
          }])
        }), _c('v-btn', {
          attrs: {
            "color": "primary",
            "disabled": _vm.FIELDS_DISABLED
          },
          on: {
            "click": _vm.addAssetsData
          }
        }, [_vm._v(" Add new ")])], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }