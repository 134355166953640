import { mapGetters } from 'vuex';
import { getFieldValueByPath } from '@/utils/object';

/**
 * Need "feedMainOptionsKey" property in component
 */
export default {
  computed: {
    ...mapGetters([
      'SUB_APPLICATIONS',
    ]),

    ownershipOptions() {
      const isFeedMainOptionsKeyNotExist = Boolean(!this.feedMainOptionsKey?.ownership);

      if (isFeedMainOptionsKeyNotExist) {
        return this.getOwnershipOptions();
      }

      return this.getOwnershipOptions(getFieldValueByPath(this, this.feedMainOptionsKey.ownership));
    },

    referenceOptions() {
      const isFeedMainOptionsKeyNotExist = Boolean(!this.feedMainOptionsKey?.reference);

      if (isFeedMainOptionsKeyNotExist) {
        return this.getReferenceOptions();
      }

      return this.getReferenceOptions(getFieldValueByPath(this, this.feedMainOptionsKey.reference));
    },
  },

  methods: {
    getOwnershipOptions(mainOptions = []) {
      const getUserName = (application) => {
        const firstName = application?.data?.personal_details?.first_name;
        const lastName = application?.data?.personal_details?.last_name;

        return `${firstName} ${lastName}`;
      };

      const externalOptions = this.SUB_APPLICATIONS?.map(getUserName);

      const filteredExternalOptions = externalOptions?.filter((option) => option?.length > 2);

      const options = [...mainOptions, ...filteredExternalOptions];

      return options;
    },

    getReferenceOptions(mainOptions = []) {
      const getUserName = (application) => {
        const firstName = application?.data?.personal_details?.first_name;
        const lastName = application?.data?.personal_details?.last_name;

        return `${firstName} ${lastName}`;
      };

      const externalOptions = [];
      const getOptions = (application) => {
        const userName = getUserName(application);

        const works = application?.data?.employments;

        const getWorks = (work) => {
          const isNotCurrentWork = Boolean(work?.current);

          if (!isNotCurrentWork) {
            return '';
          }

          const refData = {
            id: application.id,
            name: `${userName} / ${work.employer}`,
          };

          externalOptions.push(refData);
          return '';
        };

        works.forEach(getWorks);
      };

      this.SUB_APPLICATIONS?.forEach(getOptions);

      const filteredExternalOptions = externalOptions?.filter((option) => option?.name?.length > 2);

      const options = [...mainOptions, ...filteredExternalOptions];

      return options;
    },
  },
};
