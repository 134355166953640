<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-simple-table class="mt-5">
            <template #default>
              <tbody v-if="isOtherAssetsCopy">
                <tr
                  v-for="(otherAsset, idx) in otherAssets"
                  :key="idx"
                >
                  <td>
                    <v-select
                      dense
                      v-model="otherAsset.type"
                      :items="typeOptions"
                      label="Type of assets"
                      class="pa-5"
                      :disabled="FIELDS_DISABLED"
                    />
                  </td>
                  <td>
                    <v-combobox
                      v-model="otherAsset.ownership"
                      :items="ownershipOptions"
                      label="Ownership"
                      class="pa-5"
                      dense
                      :disabled="FIELDS_DISABLED"
                    />
                  </td>
                  <td>
                    <v-text-field
                      dense
                      type="text"
                      v-model="otherAsset.description"
                      placeholder="Description"
                      label="Description"
                      :disabled="FIELDS_DISABLED"
                    />
                  </td>
                  <td>
                    <v-text-field
                      dense
                      type="text"
                      v-model="otherAssetsCopy[idx].value"
                      @focus="inputNumberType($event, otherAssetsCopy?.[idx]?.value, idx)"
                      @blur="inputTextType($event, otherAssetsCopy?.[idx]?.value, idx)"
                      placeholder="Value, NZD"
                      label="Value, NZD"
                      :disabled="FIELDS_DISABLED"
                    />
                  </td>

                  <td>
                    <v-icon @click.prevent="removeAssetsData(idx)" :disabled="FIELDS_DISABLED">
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-btn color="primary" @click="addAssetsData" :disabled="FIELDS_DISABLED">
            Add new
          </v-btn>
        </div>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';
import FeedOptionsMixin from '@/mixins/FeedOptionsMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';

export default {
  name: 'CompaniesSection',

  components: {
    BaseSection,
  },

  mixins: [SectionMixin, FeedOptionsMixin],

  props: {
    existedData: {
      type: Array,
      default() {
        return [];
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      typeOptions: [
        'Cash savings', 'Investment', 'Superannuation',
        'Business', 'Content', 'Vehicle', 'Other',
      ],

      mainOwnershipOptions: ['Business', 'Joint'],

      feedMainOptionsKey: {
        ownership: 'mainOwnershipOptions',
      },

      isSectionVisible: false,

      initialOtherAssets: [],

      otherAssets: [],

      otherAssetsCopy: [],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),

    isOtherAssetsCopy() {
      return this.otherAssetsCopy.length;
    },
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialOtherAssets = clone(this.existedData);
    this.otherAssets = clone(this.existedData);
    this.otherAssetsCopy = clone(this.otherAssets);
    this.otherAssetsCopy.forEach((item, index) => {
      this.otherAssetsCopy[index].value = this.$options.filters.integerFormatter(item.value);
    });

    this.$emit('componentReady');
  },

  methods: {
    addAssetsData() {
      const otherAssetsData = {
        type: '',
        ownership: '',
        description: '',
        value: '',
      };

      this.otherAssets.push(otherAssetsData);
      this.otherAssetsCopy = clone(this.otherAssets);
      this.otherAssetsCopy.forEach((item, index) => {
        this.otherAssetsCopy[index].value = this.$options.filters.integerFormatter(item.value);
      });
    },

    removeAssetsData(idxAssets) {
      this.otherAssets.splice(idxAssets, 1);
      this.otherAssetsCopy = clone(this.otherAssets);
      this.otherAssetsCopy.forEach((item, index) => {
        this.otherAssetsCopy[index].value = this.$options.filters.integerFormatter(item.value);
      });
    },

    inputTextType(event, value, index) {
      const target = event?.target;
      target.type = 'text';
      this.otherAssetsCopy[index].value = this.$options.filters.integerFormatter(value);
      this.otherAssets[index].value = this.$options.filters.textFormatter(value);
    },

    inputNumberType(event, value, index) {
      const target = event?.target;
      target.type = 'number';
      this.otherAssetsCopy[index].value = this.$options.filters.textFormatter(value);
    },
  },
};
</script>
